.promoList-breadcrumb {
  font-weight: 400;
  font-size: 18px;
  color: #3a3a3a;
  margin-bottom: 30px;
}
.promoList-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1px;

  color: #3a3a3a;
}
.promo-searchContainer {
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  display: flex;
  flex: 1;
  margin: 30px 0;
  border: 0.5px solid rgba(0, 65, 113, 0.14);
}
.promo-searchIcon {
  height: 20px;
  width: 20px;
}
.promo-searchInput {
  margin-left: 10px;
  font-weight: 400;
  font-size: 18px;
  color: #3a3a3a;
  line-height: 21px;
  width: 100%;
  margin-right: 10px;
  border: none;
  background: transparent;
}

.promo-searchInput:focus {
  outline-width: 0;
}
.promo-profile-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.promo-profileCard {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33% - 11px);
  text-align: center;
}
.promo-profileCard-name {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: #004171;
  margin-bottom: 15px;
}
.promo-profileCard-body {
  background: #f4faff;
  border: 0.5px solid rgba(58, 58, 58, 0.16);
  border-radius: 5px;
  width: 100%;
  padding: 7px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
}
.promo-profileCard-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #3cba84;
}
.promo-profileCard-value {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
  padding: 5px 0 10px;
}

.promo-infoBox {
  /* width: 50%; */
  margin: 13px 0;
}
.promo-infoBox:nth-child(odd) {
  text-align: left;
}
.promo-infoBox:nth-child(even) {
  text-align: right;
}

.curebayContent-addButton {
  display: flex;
  margin-left: auto;
  align-items: center;
  border-radius: 5px;
  padding: 5px 30px;
  font-size: 18px;
  background-color: #004171;
  text-decoration: none;
  color: #fff;
}
.curebayContentList-listContainer {
  display: flex;
  margin-top: 20px;

  background: #ffffff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.03);
  border-radius: 7px;

  padding: 20px;
  position: relative;
  width: 100%;
}
.cureBayContentList-detailBlock {
  flex: 5;
  /* border: 0.5px solid rgba(0, 65, 113, 0.5); */
  border: 0.5px solid rgba(0, 93, 141, 0.34);
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.03));
  /* border-radius: 7px; */
  border-radius: 0 7px 7px 0;
  border-left: none;
  padding: 26px;
  padding-left: 18px;

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}
.cureBayContent-descriptionDetails {
  color: #3a3a3a;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;

  /* manage despription overflow */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}
.cureBayContent-promoMedia {
  width: 289.17px;
  height: 264px;
  /* padding: 0 10px 0 0; */
  display: flex;
  align-items: center;
  /* border: 0.5px solid rgba(0, 93, 141, 0.34); */
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.03));
  border-radius: 7px 0 0 7px;
  background-color: rgba(0, 0, 0, 0.03);
}
.cureBayContent-promoMedia .cureBayContent-promoImage
/* ,.cureBayContent-promoMedia video */ {
  object-fit: contain;
  width: 100%;
}
.cureBayContent-activeDate {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: #9d9fa2;
}
.video-wrapper {
  /* background-color: rgba(0, 0, 0, 0.5); */
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.video-wrapper .playIcon {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  cursor: pointer;
}
.cureBayContent-promoVideo {
  width: 100%;
  position: relative;
  border-radius: 5px 0 0 5px;
}
.video-wrapper-overlay::after {
  background: rgba(58, 58, 58, 0.69);
  border-radius: 5px 0 0 5px;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}
