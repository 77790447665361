.login-bgSection {
  flex: 1;
  position: relative;
}
.login-bgSection::after {
  content: "";
  background-color: #c6def4;
  border-right: 10px solid #3cba84;
  position: absolute;
  top: 0;
  right: 0;
}
.login-formSection {
  flex: 1;
  text-align: center;
  padding: 30px;
}
.login-formSectionContainer {
  max-width: 500px;
  margin: auto;
}
.login-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 62px;
  letter-spacing: 0.1px;
  color: #004172;
  margin: 20px;
}
.login-tagline {
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.1px;
  color: #3cba84;
  margin: 26px;
}
.login-subheading {
  padding: 15px;
  font-weight: 500;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: 0.1px;

  color: #3a3a3a;
  margin-bottom: 30px;
}

.login-formContainer {
  display: flex;
  flex-direction: column;
  max-width: 432px;
  margin: auto;
}
.login-formContainer label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1.5px;

  color: #004172;
  text-align: initial;
  margin-bottom: 7px;
}
.login-formContainer input::placeholder {
  color: #dcdddf;
}
.login-formContainer input:focus {
  outline: none;
}
.login-formContainer input {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border: none;
  border-bottom: 1px solid #004171;
  padding: 7px 0;
  margin-bottom: 30px;
  width: 100%;
}
.login-formBtn {
  background: #004171;
  border-radius: 5px;
  border: none;
  padding: 15px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  cursor: pointer;
  color: #ffffff;
}

.login-ErrorMsg {
  margin-bottom: 30px;
  text-align: left;
  color: red;
  font-size: 14px;
}
