.confirmation-closeIconContainer {
  text-align: right;
}

.confirmation-closeIconContainer img {
  width: 30px;
  margin: 10px;
  cursor: pointer;
}

.confirmation-approveRejectIconContainer {
  text-align: center;
  margin-top: 15px;
}

.confirmation-approveRejectIconContainer img {
  height: 100px;
  width: 100px;
}

.confirmation-overlayUpdateStatus {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: #000000;
  text-align: center;
  margin-top: 5%;
}
