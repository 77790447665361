/* Add this CSS to your stylesheets or style block */
.approve-modal {
  width: 1073px;
  height: 534px;
  position: fixed;
  top: 200px;
  left: 184px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  padding: 20px;
  z-index: 9999; /* Ensure the modal appears above other elements */
}

/* Create the faded background effect */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Adjust the alpha (last value) for the desired transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998; /* Set a lower z-index than the modal to position behind it */
}

.imagapprove {
  margin-left: 400px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
}

.statusApp {
  /* margin-left: 10px; */
  padding-left: 390px;
  font-family: "Rubik";
  font-weight: 700px;
  color: #51b955;
  font-weight: bold;
}
/* Placeholder */
.placeholder {
  padding-bottom: 50px;
  padding-left: 10px;
  width: 986px;
  height: 98px;

  border-radius: 5px;
  border: 0.5px solid #000;
  margin-top: 20px;
}
.placeholder::before {
  margin-bottom: 20px;
  content: "Please enter remarks";
  transform: translate(-50%, -50%);
  color: #999; /* You can change #999 to the desired text color */
  font-size: 16px; /* You can change the font size as needed */
}
/* Button */
.buttonyess {
  margin-top: 20px;
  width: 150px;
  height: 50px;
  border-radius: 5px;
  background-color: #004171; /* You can change the background color to your desired color */
  color: #fff; /* You can change the text color to your desired color */
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
}

/* Button hover effect (optional) */
.buttonyes:hover {
  background-color: #0056b3; /* You can change the hover background color to your desired color */
}

/* Button */
.button {
  margin-top: 20px;
  margin-left: 20px;
  width: 150px;
  height: 50px;
  border: 1px solid #004171b0; /* Add border with 1px width and the desired color */
  border-radius: 5px;
  background-color: #ffffff; /* You can change the background color to your desired color */
  color: #3a3a3a; /* You can change the text color to your desired color */
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  outline: none;
}

/* Button hover effect (optional) */
.button:hover {
  background-color: #e6f6ff; /* You can change the hover background color to your desired color */
}

.modal-containerconfirm {
  width: 631px;
  height: 161px;
  position: fixed;
  top: 374px;
  left: 547px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  padding: 20px;
  z-index: 9999;
}

.approve-modalconfirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Adjust the alpha (last value) for the desired transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
}
.modal-contentconfirm {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
