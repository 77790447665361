* {
  box-sizing: border-box;
}

.assignment-modal-overlay {
  background-color: rgba(0, 0, 0, 0.69);
  width: 100vw;
  height: 100vh;
  padding: 30px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.assignment-modal-main {
  background-color: #fff;
  height: 90%;
  width: 90%;
  position: absolute;
  margin: 30px;
  overflow-x: scroll;
}

.assignment-modal-body {
  display: flex;
  height: 100%;
  background-color: #f4faff;
  padding-left: 30px;
  padding-top: 45px;
  padding-bottom: 45px;
  padding-right: 17px;
}

.assignment-modal-gallery {
  flex: 1;
  position: relative;
  border: 1px solid #cdcdcd;
  display: flex;
  justify-content: center;
}

.assignment-modal-profile {
  flex: 1;
  position: relative;
  background-color: #fff;
  padding-right: 55px;
  padding-left: 55px;
}

.assignment-modal-gallery-img {
  min-width: 300px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.assignment-modal-previous-btn,
.assignment-modal-next-btn {
  position: absolute;
  transform: translate(0%, -50%);
  background-color: rgba(0, 0, 0, 0.69);
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0 0 2px #888;
  width: 40px;
  height: 40px;
  z-index: 9;
  top: 50%;
  cursor: pointer;
}

.assignment-modal-previous-btn {
  left: -18px;
}

.assignment-modal-next-btn {
  right: -18px;
}

.assignment-modal-previous-btn-icon,
.assignment-modal-next-btn-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
}

.assignment-modal-profile-header {
  display: flex;
  padding-top: 40px;
}

.assignment-modal-profile-title {
  color: #004171;
  font-weight: 500;
  font-size: 20px;
}

.assignment-modal-profile-time {
  color: #3a3a3a;
}

.assignment-modal-close-btn {
  color: #000;
  background-color: rgba(0, 0, 0, 0.69);
  position: absolute;
  padding: 7px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.assignment-modal-profile-image-icon {
  /* margin-right: 24px;
    margin-bottom: 35px; */
  padding: 15px;
}

.assignment-modal-approveButton {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  align-items: center;
  background: #e9ffea;
  border: 1px solid #51b955;
  border-radius: 5px;
  margin-right: 15px;
}

.assignment-modal-rejectButton {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  align-items: center;
  background: #ffebeb;
  border: 1px solid #ec5c5c;
  border-radius: 5px;
}

.assignment-modal-rejectText {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1px;
  color: #ec5c5c;
}

.assignment-modal-approveText {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1px;
  color: #51b955;
}

.assignment-modal-tickIcon {
  width: 18px;
  height: 13px;
}
