.AppHeader {
  height: 93px;
  background-color: "red";
}

.panelistBody {
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #f4faff;
  height: 90vh;
  overflow: auto;
}

.titleContainer {
  color: #3a3a3a;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 30px;
}

.filterContainer {
  margin-bottom: 30px;
  display: flex;
}

.searchContainer {
  padding: 9px;
  padding-left: 15px;
  border-radius: 5px;
  display: flex;
  flex: 1;
  margin-right: 5px;
  border: 0.5px solid rgba(0, 65, 113, 0.14);
}

.searchInput {
  margin-left: 10px;
  font-weight: 400;
  font-size: 18px;
  color: #3a3a3a;
  line-height: 21px;
  width: 100%;
  margin-right: 10px;
  border: none;
  background-color: #f4faff;
}

.searchInput:focus {
  outline-width: 0;
}

.searchIcon {
  height: 20px;
  width: 20px;
  margin: auto;
}

.cardTitle {
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
  text-transform: capitalize;
}

p {
  margin: 0;
  /* padding: 0; */
}

.panelist-dropdown {
  flex: 1;
  position: relative;
}

.sellectionContainer {
  margin-bottom: 30px;
  display: flex;
}
.sellectionContainer div {
  flex: 1;
  padding: 15px;
  cursor: pointer;
  border: 1px solid #004171;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1px;
}
.activateButton {
  background-color: #004171;
  color: #ffffff;
}
.deactivateButton {
  background-color: #ffffff;
  color: #004171;
}

.recentPanelistContainer {
  /* overflow-y:auto; */
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  /* text-align: center; */

  display: flex;
  flex-wrap: wrap;
  gap: 20px 1%;
}

.activePanelistContainer {
  /* overflow-y:auto; */
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  /* text-align: center; */
  display: flex;
  flex-wrap: wrap;
  gap: 20px 1%;
}

.activePanelist:nth-child(even) {
  /* background: rgb(239, 9, 9); */
  /* float: right; */
}
.activePanelist:nth-child(odd) {
  /* background: rgb(12, 225, 98); */
}

.cardContainer {
  display: flex;
  position: relative;
  padding: 20px;
  padding-bottom: 25px;
}
.cardContainer::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
}

.activePanelistCardContainer {
  display: flex;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 50px;
}
.countLabel {
  position: relative;
  padding: 20px;
  width: 100%;
}
.countLabel .tooltip {
  display: none;
  background-color: #fff;
  padding: 15px;
  position: absolute;
  text-align: center;
  /* bottom: 0; */

  border-radius: 5px;

  top: 76px;
  left: -40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #000000;
  z-index: 1;
  box-shadow: 0px 8px 11px rgb(0 0 0 / 9%);
  min-width: 150px;
  width: fit-content;
}
.countLabel .tooltip::before {
  content: "";

  position: absolute;
  top: -15px;
  left: 45%;

  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 15px solid #fff;
}

.countLabel:hover .tooltip {
  display: inline-table;
}
.detailsContainer {
  margin-left: 20px;
  /* background-color:blue; */
  margin-top: 0px;
}

.ProfileIcon {
  height: 88px;
  width: 88px;
}

.panelistCard {
  /* padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 25px; */
  /* display: inline-block;
  background-color: #ffffff;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.06);
  margin-right: 30px; */

  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0px 8px 11px rgb(0 0 0 / 6%);
  /* margin-bottom: 20px; */
  position: relative;
  width: 49%;
}

.panelistCard:nth-child(even) {
  /* background: rgb(239, 9, 9); */
}
.panelistCard:nth-child(odd) {
  /* background: rgb(12, 225, 98); */
  float: right;
}

.activePanelist {
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0px 8px 11px rgb(0 0 0 / 6%);
  /* margin-bottom: 20px; */
  position: relative;
  width: 49%;
}

.referData {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #63be39;
  margin-bottom: 10px;
  text-align: left;
}

.phoneIcon,
.emailIcon {
  height: 20px;
  width: 20px;
}

.phoneNumber,
.emailID {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
  margin-left: 5px;
}

.referText {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.6);
  /* margin-bottom: 30px; */
  text-align: left;
  width: 45%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.approveButton {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  align-items: center;
  background: #e9ffea;
  border: 1px solid #51b955;
  border-radius: 5px;
  margin-right: 35px;
}

.rejectButton {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  align-items: center;
  background: #ffebeb;
  border: 1px solid #ec5c5c;
  border-radius: 5px;
}

.rejectText {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1px;
}

.approveText {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1px;
}

.tickIcon {
  width: 18px;
  height: 13px;
}

.crossIcon {
  width: 18px;
  height: 13px;
}

.cardFooterIcon {
  height: 16px;
  width: 16px;
  margin-top: 4px;
  margin-right: 5px;
}

.cardFooterText {
  font-size: 18px;
  font-weight: 400;
  /* color: #ffffff; */
  color: #000;
}

.footerTextContainer {
  display: flex;
  /* margin-bottom: 5px; */
  align-items: center;
  /* margin-right: 10px; */
}

.cardFooterContainer {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  /* padding: 20px; */
  /* margin-top: 15px; */
  /* padding-left: 15px;
  padding-top: 10px;
  padding-right: 24px;
  padding-bottom: 10px; */
  /* background: linear-gradient(
    90.01deg,
    #255b89 -6.27%,
    #4dabd4 54%,
    #4fb0d9 101.31%
  ); */
  background: #e6f6ff;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}
.footerTextCount {
  color: #000;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.panelist-overlay {
  /* position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1; */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.65); */
  z-index: 2;
}

.panelist-overlayContainer {
  /* display: flex;
  height: 100%;
  justify-content: center;
  align-items: center; */
  /* display: flex; */
  height: 100%;
  /* justify-content: center; */
  /* align-items: center; */
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.65);
}

.panelist-statusUpdateBox {
  /* background-color: #f4faff;
  padding-top: 30px;
  padding-right: 8%;
  padding-left: 8%; */
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.panelist-approveRejectIconContainer {
  text-align: center;
  margin-top: 10%;
}

.panelist-approveRejectIconContainer img {
  height: 15%;
  width: 15%;
}

.panelist-closeIconContainer {
  text-align: right;
}

.panelist-closeIconContainer img {
  height: 3%;
  width: 3%;
}

.panelist-overlayUpdateStatusContainer {
  background-color: #ffffff;
  /* display: flex; */
  z-index: 3;
  width: 80%;
  height: 80%;
  padding: 20px;
  /* justify-content: center;
  align-items: center; */
}

.panelist-overlayUpdateStatus {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: #000000;
  text-align: center;
  margin-top: 5%;
}
