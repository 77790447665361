/* .dashboard-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
  margin-bottom: 30px;
}
.dashboard-subtitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
}
.dashboard-container {
  display: flex;
  gap: 24px;
  padding: 18px;
} */

/* start */
/* .tab-item {
  border: 0.6px solid rgba(0, 65, 114, 0.64);
  border-radius: 5px;
  padding: 18px;

  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: #9d9fa2;
  cursor: pointer;
}
.tab-item.active {
  background: #005d8e;
  color: #fff;
}
.tab-item.active .tab-icon {
  filter: brightness(271%);
}
.tab-icon {
  display: block;
  margin-bottom: 15px;
} */

/* start */
.counter-container {
  color: #fff;
  display: flex;
  gap: 23px;
  /* justify-content: space-between; */
}
.counter-block {
  flex: 1;
  /* width: 230px; */
  padding: 14px;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}
.counter-count {
  font-size: 57px;
  line-height: 60px;
}
/* CARD START */
.chartCard-container {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}
.chartCard {
  flex: 1;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 15px;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1px;

  color: rgba(0, 0, 0, 0.6);
}

.chartCard-count {
  font-weight: 500;
  font-size: 45px;
  line-height: 53px;
  text-align: center;
  letter-spacing: 0.1px;

  color: #000000;
}

/* CHART START */
.chart-container {
  margin-top: 30px;
  display: flex;
  gap: 30px;
}
.chartBlock-container {
  background: #ffffff;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 15px;
  flex: 1;
}
.chartBlock-container label {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
  cursor: pointer;
}
.chartBlock-container input {
  accent-color: #3a3a3a;
  cursor: pointer;
}
.chartBlock-container .chartBlock-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1px;

  color: #005d8e;
}
/* CHART WRAPPER */
.chartWrraper {
  flex: 0.5;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.chartWrraper-title {
  padding: 10px 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1px;
  border-bottom: 1px solid #cff1ff;

  color: #000000;
}
/* CHART BOX START */
.chartBox-container {
  padding: 15px;
}
.chartVertical-label {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  padding-left: 10px;
  margin-bottom: auto;
  padding-top: 26px;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;

  color: #000000;

  /* transform: rotate(-90deg); */
}
.chartHorizontal-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;

  color: #000000;
  margin-left: auto;
}

/* CHART INFOBOX START */
.chartInfobox-container {
  flex: 1;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.chartInfobox-header {
  padding: 10px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.1px;
  border-bottom: 1px solid #cff1ff;

  color: #000000;
}
.chartInfobox-progress-container {
  background-color: #ffc531;
  height: 10px;
}
.chartInfobox-progress-bar {
  width: 70%;
  height: 100%;
  background-color: #9a4de7;
}
.chartInfobox-body {
  padding: 15px;
}
.chartInfobox--countContainer {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #000000;
}
.chartInfobox-count {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  color: #000000;
  margin-top: 15px;
}
.chartInfobox-reviewCount {
  display: flex;
  gap: 7px;
  margin-top: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  /* identical to box height */

  text-decoration-line: underline;

  color: #004171;
  cursor: pointer;
}
.chart-inputGroup input {
  accent-color: #3a3a3a;
}

/* LOADER START */
.loader-container {
  background-color: rgba(255, 255, 255, 0.47);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chartInfo-progress-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 7px 0 10px;
}
.chartIngoBox-barOverflow,
.chartIngoBox-barOverflowBack {
  /* Wraps the rotating .bar */
  position: relative;
  overflow: hidden; /* Comment this line to understand the trick */
  width: 90px;
  height: 45px; /* Half circle (overflow) */
  margin-bottom: -14px; /* bring the numbers up */

  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}
.chartIngoBox-barOverflowBack {
  position: absolute;
  width: 98px;
  height: 50px;
  top: 2px;
}
.chartInfobox-progressBar,
.chartInfobox-progressBarBack {
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  height: 90px; /* full circle! */
  border-radius: 50%;
  box-sizing: border-box;
  border: 10px solid #f2f2f2; /* half gray, */
  border-bottom-color: #43aa40; /* half azure */
  border-right-color: #43aa40;
}
.chartInfobox-progressBarBack {
  /* transform: translate(-10px, -10px); */
  border: 15px solid transparent;
  border-bottom-color: rgba(76, 231, 72, 0.2); /* half azure */
  border-right-color: rgba(76, 231, 72, 0.2);
  width: 95px;
  height: 95px;
}

/* LOADER */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  opacity: 0; /* Start with opacity 0 to make it invisible */
  visibility: hidden; /* Start with visibility hidden */
  transition: opacity 0.3s ease; /* Add a smooth transition for opacity */
}

/* Show the overlay when the loader is displayed */
.loader-container.show-loader .overlay {
  opacity: 1; /* Make the overlay visible */
  visibility: visible; /* Make the overlay visible */
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 30px auto;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; /* Ensure the loader is on top of the overlay */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
