.swasthyamitracard {
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 8px 11px rgb(0 0 0 / 6%);
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  padding-left: 20px;
  flex-grow: 1;
}

.name {
  display: flex; /* This makes the children elements align in a row */
  align-items: center;
  margin-top: 10px;
}

.Details {
  display: flex; /* This makes the children elements align in a row */
  align-items: center;
}

.cardTitle {
  padding-top: 10px;
}
.request {
  margin-left: 650px;
  color: #63be39;
  padding-top: 10px;
}

.image {
  width: 88px;
  height: 88px;
  background-color: #e6f6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 50%;
  user-select: none;
  text-transform: uppercase;
}

.memberIcon {
  margin-top: 4px;
  width: 13px;
  height: 13px;
}

.botto-text {
  margin-right: 10px;

  right: 20%;
  margin-top: 10px;
  margin-left: 10px;
}

.Icon {
  margin-top: 10px;
  margin-left: 10px;

  width: 13px;
  height: 13px;
}

.Detailsrow {
  display: flex;
}

.pad {
  flex: 4;
  display: flex;
  align-items: center;
  padding: 8px;
  padding-right: 56px;
  width: 20px;
}

.block {
  /* display: flex; */
  flex-direction: column; /* This makes the children elements align in a column */
  align-items: center;
}

.withdrawdetails {
  margin-top: 20px;
  display: flex;
  margin-right: 20px;
  /* background-color: #ffffff; */
  box-shadow: 0px 8px 11px rgb(0 0 0 / 6%);
  border: 0.5px solid #00417175;
  position: relative;
  margin-bottom: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-radius: 5px;
}

.cardtwoTitle {
  padding: 10px;
  margin-top: 10px;
  margin-left: 20px;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
  text-transform: capitalize;
}
.button-align {
  display: flex;
}

.Detailsafter {
  display: flex; /* This makes the children elements align in a row */
  align-items: center;
}

.Detailsafter::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  background: rgba(7, 235, 144, 0.1);
  bottom: 0;
  left: 0;
}

.buttonApprove {
  padding: 6px 10px;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.25rem 0.125rem;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1px;
  background-color: #63be39;
}
.approveRejectBtn-icon {
  width: 18px;
  height: 13px;
}
.box2 {
  flex-direction: column;
}

.detailsnew {
  flex-direction: column;
}

.heading {
  padding-bottom: 10px;
  padding-left: 10px;
  /* padding-top: 35px; */
}

.subheading {
  padding-bottom: 20px;
  padding-left: 35px;
  font-weight: bold;
}

.approveRejectBtn {
  padding: 6px 10px;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.25rem 0.125rem;
  text-align: center;
}
.approveRejectBtn-text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1px;
}
.approveRejectBtn-icon {
  width: 18px;
  height: 13px;
}
.sett {
  flex-direction: column; /* This makes the children elements align in a column */
  align-items: center;
}
.detailsContainer1 {
  display: flex; /* This makes the children elements align in a row */
  align-items: center;
}

.Detailspro {
  flex-direction: column; /* This makes the children elements align in a column */
  align-items: center;
}

.Icon1 {
  margin-top: 10px;
  margin-left: 43px;

  width: 13px;
  height: 13px;
}

.buttonview {
  /* margin-bottom: 20px; */
  width: 133px;
  height: 40px;
  border-radius: 5px;
  background-color: #004171; /* You can change the background color to your desired color */
  color: #fff; /* You can change the text color to your desired color */
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
}

.statusP {
  margin-right: 10px;

  right: 20%;
  margin-top: 10px;
  margin-left: 339px;
}

.Iconpdf {
  margin-top: 10px;

  width: 38px;
  height: 32px;
}
