.cureBayContent-statusTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
  margin-bottom: 10px;
}
.cureBayContent-statusContainer {
  display: flex;
  gap: 18px;
}
.cureBayContent-statusContainer input {
  cursor: pointer;
}
.cureBayContent-statusContainer label {
  padding: 0 7px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
}

.cureBayContent-titleContainer {
  font-weight: 400;
  font-size: 18px;
  color: #3a3a3a;
  margin-bottom: 30px;
  /* padding-top: 20px; */
}

.cureBayContent-descContainer {
  width: 100%;
  /* height: 239px; */
  background-color: #fff;

  margin-bottom: 30px;
  font-family: sans-serif;
  position: relative;

  display: flex;
  flex-direction: column;
  border: 0.5px solid rgba(157, 159, 162, 0.62);
  border-radius: 5px 5px 0 0;
}
.cureBayContent-textarea {
  width: 100%;
  /* min-height: 200px; */
  overflow-x: hidden;

  padding: 30px;

  font-family: sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #9d9fa2;
  border: none;
  resize: vertical;
}
.cureBayContent-url {
  /* position: absolute; */
  /* left: 0;
  bottom: 0; */
  /* width: calc(100% - 36px); */
  width: 100%;
  /* margin: 18px; */
  border: 0.5px solid rgba(157, 159, 162, 0.62);
  border-top: none;

  border-radius: 0 0 5px 5px;
  padding: 15px 30px;
  font-size: 18px;
}
.curebay-imgInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 55px;
  margin: 30px;

  border: 0.5px dashed rgba(0, 65, 113, 0.24);
  border-radius: 5px;

  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 117% */

  letter-spacing: 0.1px;
  text-decoration-line: underline;

  color: #9d9fa2;
  cursor: pointer;
}
.curebay-imgInputIcon {
  width: 30px;
}
.curebay-imgInputSelected {
  width: 150px;
}
.curebay-imgInput p {
  margin: 10px;
}

.cureBayContent-statusBlock {
  margin-bottom: 30px;
  accent-color: #3a3a3a;
}

.cureBayContent-calendalContainer {
  display: flex;
  flex: 2;
  gap: 28px;
  margin-bottom: 30px;
}

.cureBayContent-startDateContainer {
  flex: 1;
}

.cureBayContent-endDateContainer {
  flex: 1;
}

.cureBayContent-sendBtnContainer {
  flex: 1;
  margin-top: auto;
}
.cureBayContent-sendBtn,
.clearImage-btn {
  background: #004171;
  color: #fff;
  padding: 15px 35px;
  width: 180px;
  border-radius: 5px;
  border: none;
  display: inline-block;
  cursor: pointer;

  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}
.image-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  overflow-wrap: break-word;
}
.submitted-msg {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;

  padding: 15px 35px;
  border-radius: 5px;
  border: none;
  display: inline-block;
  cursor: pointer;

  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}
.posting-msg {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;

  padding: 15px 35px;
  border-radius: 5px;
  border: none;
  display: inline-block;
  cursor: pointer;

  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}
