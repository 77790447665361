.panelistProfile-titleContainer {
  font-weight: 400;
  font-size: 18px;
  color: #3a3a3a;
  margin-bottom: 30px;
  text-transform: capitalize;
  /* padding-top: 20px; */
}
.panelistProfileDetails-container {
  background: #ffffff;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 28px;
}
.profileDetailsContainer {
  margin-left: 20px;
}
.panelistProfile-CardContainer {
  display: flex;
  justify-content: space-between;
}
.profileCardTitle {
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
  text-transform: capitalize;
}
.profileReferData {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #63be39;
  margin-bottom: 10px;
  text-align: left;
}
.profilePhoneNumber {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
  margin-left: 5px;
}
.profileEmailID {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
  margin-left: 5px;
}

.profileCardFooterContainer {
  display: flex;
  justify-content: space-between;
  margin: 28px 0;
  padding: 20px;
  /* margin-top: 15px; */
  /* background: linear-gradient( 90.01deg, #255b89 -6.27%, #4dabd4 54%, #4fb0d9 101.31% ); */
  background: #e6f6ff;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}

.profileSelectionBtn {
  flex: 1 1;
  text-align: center;
  /* background-color: #004171; */
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  /* color: #ffffff; */
  color: #004171;
  border: 1px solid #004171;
  cursor: pointer;

  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1px;
}
.profileSelectionBtnActive {
  background-color: #004171;
  color: #fff;
}

.panelistProfile-searchContainer {
  padding-left: 15px;

  padding-top: 10px;

  padding-bottom: 10px;

  border-radius: 5px;

  display: flex;

  flex: 1;

  margin-right: 5px;

  border: 0.5px solid rgba(0, 65, 113, 0.14);
}
.panelistProfile-searchInput {
  margin-left: 10px;

  font-weight: 400;

  font-size: 18px;

  color: #3a3a3a;

  line-height: 21px;

  width: 100%;

  margin-right: 10px;

  border: none;
  background: transparent;
}

.panelistProfile-searchInput:focus {
  outline-width: 0;
}
.btn {
  padding: 0.575rem 0.95rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 5px;
  text-decoration: none;
}
.btn-primary {
  background-color: #004171;
  color: #fff;
}
