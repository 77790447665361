.tabel-dataContainer {
  margin: 34px 0;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: #dcdddf5c;
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.06);
}

.tabel-dataContainer thead {
  color: #004172;
}

.tabel-dataContainer thead th {
  padding: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.tabel-dataContainer tbody {
  color: #3a3a3a;
  background-color: #fff;
}

.tabel-dataContainer tbody td {
  padding: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}
