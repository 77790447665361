.react-calendar {
  background-color: #f4faff;
  border: none;
}

.calendarContainer {
  display: flex;
  align-items: center;
  border: 1px solid;
  border: 0.5px solid rgba(0, 65, 113, 0.69);
  border-radius: 5px;
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
}

.calendarDate {
  flex: 8;
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  color: #3a3a3a;
}

.calendarTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
  margin-bottom: 7px;
}

.calendarIcon {
  height: 22px;
  width: 22px;
  float: right;
}

.calendarIconContainer {
  flex: 2;
  padding-right: 11px;
}

.calendarOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 3; /*header is having more zindex */
}

.calendarOverlayContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.calenderHolder {
  background-color: #f4faff;
  padding-top: 30px;
  /* padding-bottom: 30px; */
  padding-right: 8%;
  padding-left: 8%;
}

.calenderTitle {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
  margin-bottom: 2%;
  text-align: center;
}

.calendarOverlayButton {
  padding-top: 4%;
  padding-bottom: 4%;
  /* padding-right: 10%;
  padding-left: 10%; */
  width: 140px;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  cursor: pointer;
}

.calendarCancelButton {
  margin-right: 15%;
  background: #f4fbff;
  border-radius: 5px;
  color: #3a3a3a;
  border: 0.5px solid rgba(0, 65, 113, 0.69);
}

.calendarSaveButton {
  background: #004171;
  border-radius: 5px;
  color: #ffffff;
}

.calendarButtonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 8%;
  margin-top: 8%;
}
