/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.addNew-titleContainer {
  font-weight: 400;
  font-size: 18px;
  color: #3a3a3a;
  margin-bottom: 30px;
  /* padding-top: 20px; */
}

.addNew-textArea {
  width: 100%;
  padding-top: 35px;
  padding-left: 30px;
  font-weight: 400;
  font-size: 22px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #9d9fa2;
  border: 0.5px solid rgba(157, 159, 162, 0.62);
  border-radius: 5px;
  margin-bottom: 15px;
}

.addNew-filterContainer {
  display: flex;
  margin-bottom: 60px;
}

.addNew-calendalContainer {
  display: flex;
}

.addNew-addNewButtonContainer {
  flex: 1;
  position: relative;
}

.addNew-addNewButtonWrapper {
  position: absolute;
  bottom: 0;
  margin-left: 66px;
}

.addNew-startDateContainer {
  flex: 1;
  margin-right: 28px;
}

.addNew12-startDateContainer {
  flex: 1;
}

.addNew-endDateContainer {
  flex: 1;
}
.addNew-centerDateContainer {
  flex: 1;
}
.addNew-nameField {
  font-weight: 400;
  font-size: 18px;
  color: #3a3a3a;
  line-height: 21px;
  width: 100%;
  padding: 10px;
  border: 0.5px solid rgba(157, 159, 162, 0.62);
  border-radius: 5px;
  margin-bottom: 15px;
}
.addNew-statusBlock {
  margin-bottom: 15px;
  accent-color: #3a3a3a;
}
/* .addNew-statusTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1px;
  color: #3a3a3a;
  margin-bottom: 10px;
} */

.addNew-statusContainer {
  display: flex;
  gap: 18px;
}
.addNew-statusContainer input {
  cursor: pointer;
}
.addNew-statusContainer label {
  padding: 0 7px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
}
.addNew-errorMsg {
  color: red;
}

.addNew-modal-body {
  background-color: #f4faff;
  padding: 45px;
  border-radius: 5px;
  text-align: center;
}

.addNew-modal-link {
  text-decoration: none;
  display: inline-block;
}
.addButton-container {
  display: flex;
  background: #004171;
  border-radius: 5px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
}

.addButton-textContainer {
  margin-left: 8px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
}
