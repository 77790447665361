.imagePlaceholder {
  width: 88px;
  height: 88px;
  background-color: #e6f6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 50%;
  user-select: none;
  text-transform: uppercase;
}
