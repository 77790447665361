.notFound-container {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  background-color: #f4faff;
}
.notFound-link {
  background-color: #004171;
  color: #fff;
  text-decoration: none;
  padding: 15px;
  border-radius: 5px;
}
