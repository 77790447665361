.nevBlock {
  /* padding-left: 37px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-right: 20px; */
  cursor: pointer;
  display: block;
  text-decoration: none;

  padding: 22px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
  /* color: #3a3a3a; */
  color: #004171;
  display: flex;
  /* -webkit-transition: all 1.3s ease; */
}

/* .nevBlock:active {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.25);
} */

.nevBlock-active {
  /* background: rgba(60, 186, 132, 0.1); */
  background: #004171;

  /* font-weight: 600; */
  line-height: 21px;
  letter-spacing: 0.1px;
  /* color: #00654d; */
  color: #fff;
}
.nevBlock-active img {
  filter: brightness(0) invert(1);
}

/* .nevText {
  color: #4eb61e;
  font-size: 24px;
  font-weight: 400;
} */

.sidebarContainer {
  /* box-shadow: 15px 0px 53px rgba(0, 0, 0, 0.04); */
  height: 90vh;
  background: #b5dff6;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}
